import React from 'react';

export default function LandingStatistics({ id, value, name }) {
  return (
    <div className="py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="text-start">
          <div key={id} className="flex">
            <dd className="title">
              {value}
              <div className="flex-grow mt-2 pb-[1px] linearBorderTitle" />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
